/* .password-input-new {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.new-password-input,
.confirm-password-input {
  width: calc(100% - 32px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.password-toggle-new {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-toggle-new svg {
  font-size: 16px;
}

.error-message-new {
  color: red;
  font-size: 14px;
  margin-top: -20px;
}

.password-input-new,
.change-password-button {
  margin-bottom: -2px;
}

.loader {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.success-message-new {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
} */
.new-title{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color:  #0a4570;
    text-align: center;
  }
  
  .password-input-new {
    position: relative;
    margin-bottom: 1.5rem;
    border-color: #0a4570;
    cursor: pointer;
  }
  
  .password-input-new input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .password-input-new input:focus {
    outline: none;
    border-color: #0a4570;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
  }
  
  /* .password-input-new input::placeholder {
    color: #999;
  } */
  
  .password-toggle-new {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .eye-icon,
  .eye-slash-icon {
    font-size: 16px;
  }
  
  .change-password-button {
    background-color: #0a4570;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 1.2rem;
  }
  
  /* .change-password-button:hover {
    background-color: #3e8e41;
  } */
  
  .change-password-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top-color:#3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .error-message-new {
    /* color: red;
    font-size: 14px;
    margin-top: 10px; */
    left: 0;
    font-size: 0.8rem;
    color: #e31102;
    padding: 5px;
    margin-top: 3px;
  }
  
  .success-message-new {
    color: #0a4570;
    font-size: 14px;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    .password-input-new,
    .change-password-button {
      width: 100%;
    }
  
    .password-toggle-new {
      top: 50%;
      right: 15px;
      transform: translateY(-50%) translateX(-50%);
    }
  }
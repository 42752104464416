.login-container {
  max-width: 450px;
  width: 90%;
  margin-bottom: 100px; /* Adjusted margin */
  padding: 20px;
  border-radius: 10px;
  border-color: #084466;
 /* Corrected RGB syntax */
  box-shadow: 0px 0px 10px rgba(15, 5, 65, 0.1);
  
}

.login-header {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #0a4570;
  margin-bottom: 20px; /* Reduced margin-bottom */
  margin-top: 0; /* Added margin-top */
}
.er

.login-form .form-group {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.login-form .form-group label {
  margin-bottom: 5px;
  
  font-weight: 20px;
  display: flex;
  align-items: center;
}
.form-icon{
  margin-bottom: 5px;
  margin-right: 5px;
}
.login-form .form-group{
  margin-right: 10px;
  margin-top: 10px;
}

.login-form .form-group input {
  width: 100%;
  padding: 10px;
  
  box-sizing: border-box;
  border: 1px solid #0a4570; /* Changed border color to blue */
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out; /* Added transition effect */
}

.login-form .form-group input:focus {
  outline: none;
  border-color: #084466; /* Highlight color on focus */
  box-shadow: 0 0 5px rgba(8, 68, 102, 0.5); /* Subtle highlight effect */
}

.login-button {
  width: 30%;
  display: block;
  margin: 15px auto; /* Center button */
  padding: 10px;
  background-color: #0a4570;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.login-button:hover {
  background-color: #084466;
}
.erroremail-message{
  color: red;
  margin-top: 5px;
  font-size: 12px;
}
.errorapplication-message{
  color: red;
  margin-top: 5px;
  font-size: 12px;
}
/* Media query for screens up to 1024px */
@media (max-width: 1024px) {
  .login-form {
    width: 95%; /* Adjust container width for larger tablets and small desktops */
    padding: 15px; /* Adjust padding */

  }
  .login-container {
    max-width: 700px;

    width: 100%;
    margin-bottom: 100px; /* Adjusted margin */
    padding: 20px;
    border-radius: 10px;
    border-color: #084466;
   /* Corrected RGB syntax */
    box-shadow: 0px 0px 10px rgba(15, 5, 65, 0.1);
    
  }
  .form-group{
    font-size: 20px;
    margin-top: 30px;
  }


  .login-button {
    width: 30%; /* Adjust button width for medium screens */
    padding: 10px; /* Adjust padding for button */
    font-size: 30px; /* Slightly smaller font size */
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .login-container {
    width: 90%; /* Adjust container width for medium screens */
    padding: 15px;
  }

  .login-form .form-group input {
    padding: 9px; /* Adjust input padding */
  }

  .login-button {
    width: 30%; /* Adjust button width */
    padding: 9px;
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .login-container {
    width: 100%; /* Full width for small screens */
    padding: 10px;
  }

  .login-form .form-group input {
    padding: 10px; /* Reduce padding */
  }

  .login-button {
    width: 100%; /* Full width button */
    padding: 8px;
    font-size: 14px;
  }
}


/* Media query for responsiveness */
@media (max-width: 600px) {
  .login-container {
    padding: 10px;
    width: 300px;
  }

  .login-form .form-group input {
    padding: 8px;
    width: 98%;
  }

  .login-button {
    padding: 8px;
    font-size: 14px;
    width: 60%; /* Adjust button width for smaller screens */
  }
}

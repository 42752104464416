.quiz-search-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px auto;

    margin-bottom: 500px;
}

.quiz-search-form {
    display: flex;
    align-items: center;
}

.search-box {
    position: relative;
    flex: 1;
}

.search-input {
    width: 500px;
    padding: 15px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
}

.search-input:hover {
    border-color: #0a4570;
    /* Blue border on hover */
}

.dropdown-content {
    position: absolute;
    width: calc(100% - 2px);
    /* Adjusted for border width */
    top: calc(100% + 10px);
    left: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-height: 400px;
    /* Limit dropdown height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.quiz-item {
    padding: 15px 20px;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
}

.quiz-item:hover {
    background-color: #f0f0f0;
}

.search-button {
    background-color: #0a4570;
    color: white;
    border: none;
    padding: 15px 40px;
    /* Adjust padding as needed */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 40px;
}

.search-button:hover {
    background-color: #08365e;
}

@media (max-width: 600px) {
    .quiz-search-container {
        margin-left: 5px;
        margin-right: 5px;
    }

    .search-input {
        width: calc(100% - 40px); /* Increase input width for better fit */
        /* Remove max-width constraint */
    }

    .search-button {
        font-size: 14px; /* Adjust font size */
        padding: 8px 15px; /* Reduce padding for smaller button */
        margin-top: 5px; /* Adjust top margin for spacing */
        margin-left: 5px; /* Reduce left margin */
        margin-bottom: 5px; /* Adjust bottom margin */
    }

    .dropdown-content {
        width: calc(100% - 20px); /* Adjust dropdown width */
        left: 10px; /* Align dropdown */
    }
}


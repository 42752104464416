/* InstructionsPage.css */

.instructions-page {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.instructions-title {
    color: red;
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.instructions-list {

    padding-left: 20px;
    margin-left: 20px;
    max-height: 400px;
    overflow-y: auto;
}



.terms-checkbox {
    display: left; 
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 17px;
    font-weight: bold; 
}

.start-button-container {
    text-align: center;
    margin-top: 20px;
}

.start-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0a4570;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.start-button:hover {
    background-color: #083c5a;
}

/* Responsive Design */

@media (max-width: 768px) {
    .instructions-page {
        padding: 15px;
    }

    .instructions-title {
        font-size: 22px;
    }

    .instructions-list {
        padding-left: 20px;
        margin-left: 10px;
    }

    .terms-checkbox {
        display: left;
        margin-top: 15px;
       margin-left: 15px;
    }

    .start-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .instructions-page {
        padding: 10px;
    }

    .instructions-title {
        font-size: 20px;
    }

    .instructions-list {
        padding-left: 20px;
    }

    .terms-checkbox {
        margin-top: 10px;
    }

    .start-button {
        padding: 6px 12px;
        font-size: 12px;
    }
}

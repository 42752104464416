.otp-form {
  max-width: 800px;
margin-right: 15px;
margin-bottom: 19px;

  padding: 20px;

  border-radius: 5px;

}

.otpo-title {
    font-size: 2rem;
    font-weight: bold;
    margin-: 2rem;
    color:  #0a4570;
    text-align: center;
  }
  
  .otpo-input {
    position: relative;
    margin-bottom: 1.5rem;
    border-color: #0a4570;
    cursor: pointer;
  }
  
  .otpo-input input {
    width: 70%;
    padding: 0.5rem;
    margin-left: 25px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .otpo-input input:focus {
    outline: none;
    border-color: #0a4570;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
  }
  
  .otpo-submit {
    /* width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0a4570;
    color: #fff;
    cursor: pointer; */
    background-color: #0a4570;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 1.2rem;
  }
  
  .otpo-submit:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .otpo-submit-button {
    margin-top: 10px;
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .error-message-otpo {
    /* color: red;
    text-align: center;
    margin-top: 10px; */
    left: 0;
    font-size: 0.8rem;
    color: #e31102;
    padding: 5px;
    margin-top: 3px;
  }
  
  @media (max-width: 768px) {
    .otpo-form {
      width: 100%;
    }
  
    .otpo-submit {
      width: 100%;
    }
  }